.item {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	margin-right: 4px;
	padding: 4px 8px;
	font-size: 12px;
	line-height: 16px;
	border-radius: 4px;
	background-color: #F2F3F8FF;
	color: #7A89A6;

	&.invalid {
		color: #E34848FF;
		background-color: #FBEEEEFF;
	}

	&.valid {
		color: #1EBC4AFF;
		background-color: #E6F5E9FF;
	}
}
