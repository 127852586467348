.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 14px;
    align-items: flex-start;
  }
}

.filter {
  display: grid;
  grid-template-columns: 200px 150px 150px;
  gap: 24px;
  align-items: end;

  @media screen and (max-width: 768px) {
    width: 100%;
    grid-template-columns: 1fr;
  }
}