@import 'src/styles/variables.scss';

.wrapper {
  width: fit-content;
  height: 40px;
  border-radius: 6px;
  background: $tertiary-bg;
}

.fullWidth {
  width: 100%;
}
