.wrapper {
  :global {
    .ant-tabs-tab {
      font-size: 16px;
      font-weight: 500;
      color: #77779a;
      padding: 0 0 5px;

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #386ff6;
        text-shadow: none;
      }
    }
    .ant-tabs-ink-bar {
      background: #386ff6;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: 1px solid rgba(163, 163, 191, 0.24);
    }
  }
}
