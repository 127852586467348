.item {
	width: 100%;
}

.block {
	display: flex;
	align-items: center;
	gap: 24px;
}

.submit {
	width: 100%;
	margin-top: 24px;
}

:global {
	.ant-picker-time-panel-column {
		&:after {
			height: 0;
		}
	}
}

.space {
	display: flex;
	align-items: center;
	gap: 14px;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
	}
}

.switch {
	display: flex;
	align-items: center;
	gap: 8px;
}