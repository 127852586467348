@import 'src/styles/variables.scss';

.layout {
  display: grid;
}

.layout.auth {
  grid-template-areas:
    'nav  main';
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: 72px 1fr;
  position: relative;

  @media (max-width: 991px) {
    grid-template-rows: 48px 1fr;
  }
}
