@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';


.wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	background-color: #a3caea;
	padding:  12px 16px;
	border-radius: 8px;
	z-index: 1;
	width: 100%;
	position: absolute;
	top: 140px;

	@media screen and (max-width: 768px){
		top: 85px;
	}
}

.form {
	display: grid;
	gap: 32px;
	grid-template-columns: repeat(5, 1fr);
	width: 100%;

	@media screen and (max-width: 768px){
		grid-template-columns: 1fr;
		gap: 14px;
	}
}

.item {
	width: 100%;
}

.bg {
	height: 280px;
	position: fixed;
	top: 70px;
	left: 40px;
	width: 100%;

	@media screen and (max-width: 768px){
		height: 130px;
		top: -43px;
		left: 23%;
	}
}

.block {
	position: relative;
}

.change {
	border: none;
	outline: none;
	cursor: pointer;
	color: white;
	background-color: #256eff;
	position: absolute;
	padding: 6px 6px;
	border-radius: 50%;
	z-index: 1;
	top: 6px;
	right: -31px;
	width: 30px;
	display: flex;
	align-items: center;
	height: 30px;
	justify-content: center;

	@media screen and (max-width: 768px) {
		top: 32px;
		right: 10px;
		z-index: 2;
		transform: rotate(90deg);
		width: 30px;
		display: flex;
		align-items: center;
		height: 30px;
		justify-content: center;
	}
}

.field {
	min-width: 210px;
	position: relative;

	@media screen and (max-width: 991px) {
		min-width: 130px;
	}

	&:first-child {
		@media screen and (max-width: 768px) {
			margin-right: 0;
		}
	}
}

.back {
	width: 100%;
	background-image: url('./bg.jpg');
	height: 280px;
	background-size: cover;
	background-repeat: no-repeat;

	@media screen and (max-width: 768px){
		background-position-x: -100px;
		height: 200px;
	}
}

.container {
	padding: 24px 40px;
	height: 100%;
	position: relative;
	top: -287px;

	@media screen and (max-width: 768px){
		top: -195px;
		padding: 14px;
	}
}

.title {
	padding-bottom: 12px;
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 10;
	color: white;


	&.bordered {
		border-bottom: 1px solid $grey-light-1;
	}
}

.actions {
	@media screen and (max-width: 768px) {
		display: none;
	}
}