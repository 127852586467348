@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
	padding: 24px 40px;
	height: 100%;
	overflow-y: auto;

	&.smallPadding {
		padding-top: 10px;
	}


	@media screen and (max-width: 768px) {
		padding: 14px;
	}
}

.title {
	padding-bottom: 12px;
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 10;

	&.bordered {
		border-bottom: 1px solid $grey-light-1;
	}
}

.actions {
	@media screen and (max-width: 768px) {
		display: none;
	}
}