@import 'src/styles/variables.scss';

:global {
  .ant-modal-content {
    height: 100%;
    padding: 24px;
    border-radius: 6px !important;
  }

  .ant-modal {
    border-radius: 6px !important;
    box-shadow: 0 32px 64px rgba(12, 0, 48, 0.16);
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-body {
    padding: 0;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
}

.actionWrapper {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.headerWrapper {
  margin-bottom: 8px;
}
