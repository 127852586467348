.rowConfirm {
  background-color: #21c550;
}

.rowRebooked {
  background-color: #e3da29;
}

.rowCarrierConfirm {
  background-color: #218cc5;
}

.rowCanceled {
  background-color: #e8610c;
}

.confirmed {
  background-color: #00ff47;
}
.unconfirmed {
  background-color: #ff0000;
}