@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.delete {
	@include reset-button;
	color: $red-txt;
}

.services {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 12px;
}

.tag {
	margin: 8px 4px;
}

.logo {
	width: 70px;
	height: 70px;
}

.emptyLogo {
	width: 70px;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $tertiary-bg;
	color: $grey-medium-txt;
}