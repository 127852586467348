.breadcrumbs {
	margin-bottom: 16px;
}

.form {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	max-width: 680px;
	margin-top: 32px;
}

.details {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	margin-top: 32px;
}