@import 'src/styles/variables.scss';
@import 'src/styles/main.scss';

.wrapper {
  width: fit-content;
  height: 40px;
  border-radius: 6px;
  background: $tertiary-bg;
}

.fullWidth {
  width: 100%;
}

:global {
  .ant-picker-panel-container {
    border-radius: $border-radius-button;
  }

  .ant-picker-range {
    &:hover {
      border-color: $primary-bg;
    }
  }

  .ant-picker-focused {
    border-color: $primary-bg;

    &:hover {
      border-color: $primary-bg;
    }
  }

  .ant-picker-range .ant-picker-active-bar {
    background: $primary-bg;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
    &:before {
      border-color: $primary-bg;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: $primary-bg;
    border-radius: $border-radius-cell !important;
  }
}
