@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.item {
	width: 100%;
}

.cityItem {
	@include reset-button;
	width: 100%;
	text-align: left;
}

.searchBlock {
	display: flex;
	flex-direction: column;
	gap: 32px;
}

:global {
	.ant-dropdown-menu {
		max-height: 400px;
		overflow: auto;
	}
}