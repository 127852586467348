.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.actionGroup {
  display: flex;
  gap: 8px;
  align-items: center;
}
