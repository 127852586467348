@import 'src/styles/variables.scss';

.h1 {
  line-height: $line-height-62;
  font-size: $font-size-44;
  font-weight: $font-weight-700;
  font-family: $font-family--default;
}

.h2 {
  line-height: $line-height-40;
  font-size: $font-size-32;
  font-weight: $font-weight-700;
  font-family: $font-family--default;
}

.h3 {
  line-height: $line-height-32;
  font-size: $font-size-24;
  font-weight: $font-weight-700;
  font-family: $font-family--default;

  @media screen and (max-width: 768px) {
    line-height: 20px;
    font-size: 18px;
  }
}

.h4 {
  line-height: $line-height-24;
  font-size: $font-size-20;
  font-weight: $font-weight-700;
  font-family: $font-family--default;
}

.h5 {
  line-height: $line-height-24;
  font-size: $font-size-16;
  font-weight: $font-weight-600;
  font-family: $font-family--default;
}

.h6 {
  line-height: $line-height-24;
  font-size: $font-size-16;
  font-weight: $font-weight-500;
  font-family: $font-family--default;
}

.main {
  line-height: $line-height-20;
  font-size: $font-size-14;
  font-weight: $font-weight-400;
  font-family: $font-family--default;

  &.fw500 {
    font-weight: $font-weight-500;
  }

  &.fw600 {
    font-weight: $font-weight-600;
  }
}

.small {
  line-height: $line-height-16;
  font-size: $font-size-12;
  font-weight: $font-weight-400;
  font-family: $font-family--questrial;
}

.table {
  font-size: $font-size-11;
  line-height: $line-height-16;
  font-weight: $font-weight-500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}

.primary {
  color: $blue;
}

.green {
  color: $complete-txt;
}

.secondary {
  color: $secondary-txt;
}

.grey {
  color: $grey-txt;
}

.grey-medium {
  color: $grey-medium-txt;
}

.secondary-light {
  color: $secondary-light-txt;
}

.red {
  color: $red-txt;
}

.yellow {
  color: #F0DE36;
}