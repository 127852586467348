@import 'src/styles/variables.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-button;
  border: none;
  cursor: pointer;
  padding: 0;
  height: auto;
  font-weight: $font-weight-500;
  font-size: $font-size-14;
  line-height: 18px;
  box-shadow: none;

  &.fullWidth {
    width: 100%;
  }

  &.hide-pointer {
    cursor: default;
  }

  &.primary.outline {
    background: none;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $white;
    font-family: $font-family--default;
    padding: 8px 36px;

    &:hover {
      background: none;
    }

    &:enabled:active {
      background: none;
    }
  }

  &.primary {
    background: $primary-bg;
    color: $white;

    &:hover {
      background: $primary-bg-hover;
      color: $white;
    }

    &:enabled:active {
      background: $primary-bg-pressed;
      color: $white;
    }

    &:disabled {
      background: $primary-bg-disabled;
      color: $white;
      box-shadow: $box-shadow;
    }

    &.isLoading {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)), #256eff;
    }
  }
  &.isLoading {
    min-width: 100px;
  }

  &.secondary {
    background: $secondary-bg;
    color: $white;

    &:hover {
      background: $secondary-bg-hover;
      color: $white;
    }

    &:enabled:active {
      background: $secondary-bg-pressed;
      color: $white;
    }

    &:disabled {
      background: $secondary-bg-disabled;
      color: $white;
      box-shadow: $box-shadow;
    }
  }

  &.tertiary {
    background: $tertiary-bg;
    color: $primary-txt;

    &:hover {
      background: $tertiary-bg-hover;
      color: $primary-txt;
    }

    &:enabled:active {
      background: $tertiary-bg-pressed;
      color: $primary-txt;
    }

    &:disabled {
      background: rgba($white, 0.64);
      color: rgba($secondary-txt, 0.64);
    }
  }

  &.ghost {
    background: none;
    color: $secondary-txt;

    &:hover {
      background: $ghost-bg-hover;
      color: $secondary-txt;
    }

    &:enabled:active {
      background: $ghost-bg-pressed;
      color: $secondary-txt;
    }

    &:disabled {
      background: none;
      color: $secondary-light-txt;
    }
  }

  &.small {
    height: 32px;
    padding: 7px 16px;

    svg {
      max-height: 18px;
    }
  }

  &.smallSquare {
    height: 32px;
    width: 32px;
    padding: 9px;

    svg {
      max-width: 14px;
      max-height: 14px;
    }
  }

  &.middle {
    height: 40px;
    padding: 11px 16px;

    svg {
      max-height: 20px;
    }
  }

  &.large {
    height: 48px;
    padding: 15px 16px;
    font-weight: 700;

    svg {
      max-height: 22px;
    }
  }

  &.onlyIcon {
    padding: 7px;
  }
}

.dotFlash {
  margin: 5px auto;
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  @keyframes dotFlashing {
    0% {
      background-color: #9880ff;
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
  }
  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    left: -10px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }
  &::after {
    left: 10px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }
}


.grey {

  &.outline {
    color: $grey-dark-2;
    border: 2px solid $grey-light-2;
  }
}