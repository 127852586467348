@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';


.actions {
	display: flex;
	align-items: center;
	gap: 44px;
}

.action {
	@include reset-button;

	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $grey-dark-1;

	&:hover {
		color: $grey-dark-2;
	}
}