@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.dragger {
  width: 180px !important;
  height: 180px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed $grey-light-1 !important;
  position: relative;

  &:hover {
    border-color: $blue !important;
  }
}

.hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.uploadHint {
  color: $grey-dark-2;
  font-weight: 700;
  font-family: $font-family--default;
}

.uploadHintType {
  color: $grey-dark-1;
  font-family: $font-family--default;
  max-width: 130px;
}

.delete {
  @include reset-button;

  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px rgba(9, 10, 11, 0.1);
  background-color: $white;
  color: $grey-dark-2;

  &:hover {
    background-color: $grey-light-1;
  }
}

:global {
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    color: $grey-light-1;

    &:hover {
      color: $blue;
    }
  }

  .ant-upload.ant-upload-drag.ant-upload-disabled {
    cursor: pointer !important;
  }

  .ant-upload.ant-upload-disabled {
    cursor: pointer !important;
  }

  .ant-modal-footer {
    display: flex !important;
  }
}
