@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: $default-bg;
  background-repeat: no-repeat;
  flex-direction: column;
  background-size: cover;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 58px;
  padding: 12px 24px;
}

.content {
  height: calc(100% - 58px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  border: 1px solid #ecf2f6;
  background-color: white;
  border-radius: 8px;
  padding: 24px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  gap: 24px;
  min-width: 400px;
  //box-shadow: 3px 3px 10px 3px #dddddd;
}

.field {
  width: 100%;
}