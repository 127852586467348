@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 32px;
}

.sectionTitle {
	margin-bottom: 8px;
}

.services {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	flex-direction: column;
}

.actions {
	display: flex;
	align-items: center;
	gap: 14px;
}

.block {
	display: flex;
	align-items: flex-start;
	gap: 44px;

	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;

	}
}

.logo {
	width: 300px;
	height: 300px;
}

.servicesContent {
	display: flex;
	flex-wrap: wrap;
	gap: 14px;
	max-width: 500px;
}

.info {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.emptyLogo {
	width: 300px;
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $tertiary-bg;
	color: $grey-medium-txt;
}

.refundBlock {
	width: 100%;
	display: grid;
	grid-template-columns: 680px 1fr;
	grid-column-gap: 14px;
	align-items: start;
	//margin-bottom: 32px;
	margin-top: 14px;
}

.refundContent {
	border-left: 1px solid $grey-light-1;
	padding-left: 24px;
	display: flex;
	flex-direction: column;
}

.refundItem {
	display: flex;
	align-items: center;
	gap: 4px;
}