.form {
	display: flex;
	flex-direction: column;
	gap: 18px;
	width: 100%;
}

.hint {
	display: flex;
	margin-top: 8px;
}