@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.row {
	display: flex;
	align-items: center;
	gap: 14px;
	width: 100%;
}

.rowGrid {
	display: grid;
	grid-template-columns: .4fr .4fr .2fr;
	grid-column-gap: 24px;
	align-items: end;
}

.item {
	width: 100%;
}

.wrap {
	width: 100%;
	max-width: 680px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 24px;
}

.refundBlock {
	width: 100%;
	display: grid;
	grid-template-columns: 680px 1fr;
	grid-column-gap: 14px;
	align-items: start;
}

.refundContent {
	border-left: 1px solid $grey-light-1;
	padding-left: 24px;
	display: flex;
	flex-direction: column;
}

.refundItem {
	display: flex;
	align-items: center;
	gap: 4px;
}

.durationBlock {
	margin-top: 14px;
	display: grid;
	align-items: center;
	grid-column-gap: 24px;
	grid-template-columns: repeat(3, 1fr);
}

.relativeBlock {
	position: relative;
	top: -80px;
}

.content {
	display: flex;
	align-items: flex-end;
	gap: 32px;
}