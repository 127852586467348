.wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 32px;
	gap: 32px;
	padding: 4px 4px 32px;
	overflow: auto;
	position: relative;
	margin-bottom: 100px;
	top: 210px;

	@media screen and (max-width: 768px){
		top: 370px;
	}
}

.partners {
	display: flex;
	flex-direction: column;
	gap: 24px;
}