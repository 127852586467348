@import 'src/styles/variables.scss';

:global {
  .ant-form-item-explain-error {
    color: $error;
    font-size: 12px;
    position: absolute;
  }
  .ant-input::placeholder,
  .ant-select-selector .ant-select-selection-placeholder {
    color: $grey-medium-txt;
  }

  .ant-input,
  .ant-select,
  .ant-input-affix-wrapper,
  .ant-checkbox-wrapper .ant-checkbox {
    border: 1px solid transparent !important;
    outline-width: 3px !important;
    outline-style: solid !important;
    outline-color: transparent !important;
    transition: all 0.3s ease-out;
  }

  .ant-input:focus,
  .ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
  .ant-select-focused.ant-select-open {
    outline-color: rgba(91, 77, 255, 0.16) !important;
    border-color: $primary-bg !important;
  }

  .ant-form-item-has-error {
    & .ant-select,
    .ant-input,
    .ant-checkbox,
    .ant-input-affix-wrapper-status-error {
      outline-color: $error-outline !important;
      border-color: $error !important;
    }
  }
  .ant-input-affix-wrapper input {
    outline: none !important;
    border: none !important;
  }

  .ant-form-item-explain {
    position: absolute;
    display: flex;
    width: 100%;
  }
}
