@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

.result {
  display: flex;
  flex-direction: column;
  background-color: $grey-light-1;
  padding: 24px 32px;
  border-radius: 8px;
  min-width: 80%;
  margin-top: 24px;

  @media screen and (max-width: 768px) {
    min-width: 100%;
    width: 100%;
    padding: 14px;
  }
}

.title {
  width: 100%;
  text-align: center;
}

.passengers {
  background-color: $white;
  margin-top: 14px;
  padding: 8px 16px;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 32px;
}

.item {
  display: grid;
  grid-template-columns: repeat(3, 200px);

  &.head {
    font-weight: 700;
    margin-bottom: 14px;
    border-bottom: 1px solid $grey-light-2;
  }

  &.body {
    border-bottom: 1px solid $grey-light-2;
    margin-bottom: 8px;
    padding-bottom: 8px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(3, 113px);
  }
}

.passengersBody {
  max-height: 150px;
  overflow-y: scroll;
}