@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.content {
	display: flex;
	flex-direction: column;
	max-width: 680px;
	gap: 24px;
	margin: 32px 0 24px;
}

.item {
	width: 100%;
}

.rowGrid {
	display: grid;
	grid-template-columns: .4fr .4fr .2fr;
	grid-column-gap: 24px;
	align-items: end;
}

.refundBlock {
	width: 100%;
	display: grid;
	grid-template-columns: 680px 1fr;
	grid-column-gap: 14px;
	align-items: start;
	//margin-bottom: 32px;
	margin-top: 14px;
}

.refundContent {
	border-left: 1px solid $grey-light-1;
	padding-left: 24px;
	display: flex;
	flex-direction: column;
}

.refundItem {
	display: flex;
	align-items: center;
	gap: 4px;
}

.block {
	display: flex;
	align-items: center;
	gap: 14px;
}

.submit {
	margin-top: 32px;
}

.nameInfo {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 24px;
}

.phones {
	display: flex;
	align-items: center;
	gap: 14px;
}