@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	background-color: $white;
	padding: 12px 14px;
	border-radius: 8px;
}

.header {
	margin-bottom: 14px;
}

.row {
	display: flex;
	width: 100%;
	gap: 24px;
}

.formItem {
	width: 100%;
}

.changePassword {
	@include reset-button;
	margin-top: 8px;
	font-size: 11px;
	line-height: 12px;
	color: $primary;
}

.form {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.contacts {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	margin-top: 14px;
}