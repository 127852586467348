@import 'src/styles/variables.scss';

.link {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 14px;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  padding: 21px 11px 21px 30px;
  text-decoration: none;
  color: #B1C2CF;
  font-weight: $font-weight-500;
  font-size: $font-size-16;
  transition: background-color .3s $animation-timing-function ,color .3s $animation-timing-function ;


  &:hover {
    color: $primary;
    background-color: $white;

    svg {
      filter: grayscale(0);
    }
  }

  svg {
    filter: grayscale(100%);
    transition: all 0.2s ease-in-out;
  }

  &.closed {
    padding-left: 12px !important;
  }
}

.active {
  color: $primary;
  background-color: $white;
  border-left: 4px solid $primary;
  padding-left: 26px;

  svg {
    filter: grayscale(0);
  }

  &.closed {
    border-width: 2px;
  }
}
