@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.item {
	width: 100%;
	background-color: #f4faff;
	border-radius: 8px;
	padding: 16px 32px;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	display: grid;
	grid-template-columns: 1fr .5fr .6fr;
	position: relative;
	//gap: 100px;

	&.recommended {
		border: 1px solid $success;
	}

	&.priceLabel {
		border: 1px solid $red-txt;
	}

	@media screen and (max-width: 768px){
		grid-template-columns: 1fr;
		gap: 14px;
		padding: 16px 14px;
	}
}

.favorite {
	color: $success;
	position: absolute;
	display: flex;
}

.tripDate {
	display: flex;
	align-items: baseline;
	gap: 4px;
}

.date {
	background-color: #B1C2CF;
	padding: 4px;
	border-radius: 4px;
}

.duration {
	margin-left: 12px;
}

.price {
	padding-left: 32px;
	border-left: 2px dotted #B1C2CF;
	display: flex;
	gap: 24px;

	@media screen and (max-width: 768px) {
		padding-left: 0;
		border-left: none;
	}
}

.selectButton {
	width: 150px;
	margin-left: auto;
}

.actions {
	display: flex;
	gap: 24px;
	margin-top: 24px;

	.action {
		@include reset-button;


		.actionText {
			font-weight: 600;
		}
	}

	@media screen and (max-width: 768px) {
		margin-top: 0;
	}
}

.service {
	margin-top: 14px;
	display: flex;
	align-items: center;
	gap: 14px;
}

.hide {
	@media screen and (max-width: 768px){
		display: none;
	}
}

.mobile {
	display: none;

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}