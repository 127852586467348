@import 'src/styles/variables.scss';

.notAuth {
  width: 100%;
  height: 100%;
}


.content {
  //padding: 32px 32px 0;
  grid-area: main;
  display: flex;
  flex-direction: column;
  grid-template-areas: none;
  height: 100vh;
  overflow-y: scroll;

  @media (max-width: 991px) {
    padding: 16px 24px;
  }

  @media (max-width: 769px) {
    padding: 16px;
  }
}
