@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.form {
  max-width: 680px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 768px){
    max-width: 100%;
  }
}

.submit {
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 4px;

  @media screen and (max-width: 768px){
    margin-top: 0;
  }
}

.content {
  display: grid;
  grid-template-columns: 680px 650px;
  grid-column-gap: 24px;

  @media screen and (max-width: 768px){
    grid-template-columns: 1fr;
  }
}

.switchBlock {
  display: flex;
  align-items: center;
  gap: 8px;
}

.moreOptions {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.passengersWrapper {
  margin-top: -26px;
}

.passengers {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

}

.passenger {
  display: flex;
  background-color: #e9f4fb;
  border-radius: 8px;
  padding: 16px 32px;
  gap: 24px;

  @media screen and (max-width: 768px){
    flex-direction: column;
    gap: 14px;
    padding: 16px 14px;
  }
}

.name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.actions {
  display: flex;
  margin-left: auto;
  gap: 14px;
  align-items: center;
}

.action {
  @include reset-button;
  height: 38px;
  display: flex;
  align-items: center;
}