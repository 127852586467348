@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  padding-bottom: 12px;

  &:not(:first-child) {
    margin-top: 12px;
  }

  &.disableDrag {
    padding-left: 36px;
  }
}

.drag {
  cursor: grab;
  width: 36px;
  height: 36px;
  color: $grey-light-2;

  &:hover {
    color: $grey-dark-2;
  }
}

.info {
  display: grid;
  grid-template-columns: 1fr 1fr 60px;
  width: 100%;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.action {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}