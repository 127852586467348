@import 'src/styles/variables.scss';

:global {
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    background-color: transparent;
  }

  .ant-table-thead > tr > th {
    background: transparent;
  }

  .ant-table-column-title {
    flex: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  th.ant-table-cell {
    flex: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $grey-txt !important;
  }

  .ant-table-column-sorters {
    color: $grey-txt;
    justify-content: flex-start;
  }
}
