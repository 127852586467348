@import 'src/styles/mixins.scss';


.filters {
  display: flex;
  margin-bottom: 14px;
  flex-direction: column;
  gap: 12px;
}

.filtersContent {
  display: flex;
  align-items: center;
  gap: 24px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 14px;
  }
}

.search {
  min-width: 350px;
}

.date {
  min-width: 150px;

  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
}

.switchFilter {
  @include reset-button;

  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.active {
  transform: rotate(180deg);
}