@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
	color: $white;
	height: 100%;
	position: relative;
}

.content {
	height: 100%;
}

.actions {
	position: absolute;
	bottom:35px;
	display: flex;
	width: 100%;
	max-width: 300px;
	padding: 16px 16px 16px 32px;
	background: none;
	outline: none;
	border: none;
	transition: background-color .3s $animation-timing-function ,color .3s $animation-timing-function ;
	cursor: pointer;
	z-index: 10;

	&.closed {
		padding-left: 9px;
		max-width: 40px;
	}

	@media screen and (max-width: 768px){
		position: relative;
		bottom: 0;
	}
}

.hide {
	display: none;
}

.logout {
	display: flex;
	align-items: center;
	gap: 24px;
	font-weight: 600;
	cursor: pointer;
}

.sider {
	width: 300px;
	min-width: 300px !important;
	max-width: 300px !important;
	background-color: $grey-light-3;
	height: calc(100vh - 59px);

	&.closed {
		width: 40px !important;
		min-width: 40px !important;
		max-width: 40px !important;
	}

	@media screen and (max-width: 768px) {
		display: none;

		&.opened {
			display: block !important;
			width: 300px !important;
			min-width: 300px !important;
			max-width: 300px !important;
			position: absolute;
			z-index: 100;
		}
	}
}

.hover {
	z-index: 100;
}

.menu {
	width: 20px;
	height: 20px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: 0.5s;
	background: none;
	border: none;
	cursor: pointer;
	position: relative;
	top: 16px;
	outline: none;

	&.rotate {
		//transform: rotateZ(180deg);
	}

	.menuLine:first-child {
		width: 100%;
		height: 2px;
		background: #000;
		transition: 0.6s;

		&.rotate {
			transform: rotateZ(45deg);
		}
	}

	.menuLine:nth-child(2) {
		width: 80%;
		height: 2px;
		margin-top: 0.7rem;
		background: #000;
		transition: 0.6s;

		&.rotate {
			width: 100%;
			transform: translate(0, -0.8rem) rotateZ(-45deg);
		}
	}

	@include phone {
		display: flex;
	}
}

.shadow {
	display: none;

	@media screen and (max-width: 768px) {
		display: block;
		width: 100%;
		position: absolute;
		height: 100%;
		background: rgba(0,0,0,0.5);
		z-index: 20;
	}
}