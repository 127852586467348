//Colors
//---------------------------------
//background colors
$primary-bg: #256eff;
$primary-bg-hover: linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)), #256eff;
$primary-bg-pressed: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)), #256eff;
$primary-bg-disabled: linear-gradient(0deg, rgba(255, 255, 255, 0.48), rgba(255, 255, 255, 0.48)),
  #256eff;
$secondary-bg: #fb6220;
$secondary-bg-hover: #ff4d00;
$secondary-bg-pressed: #d24609;
$secondary-bg-disabled: linear-gradient(0deg, rgba(255, 255, 255, 0.48), rgba(255, 255, 255, 0.48)),
  #fb6220;
$tertiary-bg: #f4f4f7;
$tertiary-bg-hover: #eceef4;
$tertiary-bg-pressed: #d3d6e2;
$ghost-bg-hover: #eceef4;
$ghost-bg-pressed: #d3d6e2;
$default-bg: #f4f4f7;
$default-bg-hover: #f2f3f8;
$spin-bg: rgba(255, 255, 255, 0.5);
$black50: rgba(9,10,11,0.5);

$black20: rgb(213, 105, 105);

//text colors
$primary-txt: #202025;
$secondary-txt: #77779a;
$grey-txt: #7d7da2;
$grey-medium-txt: #a3a3bf;
$secondary-light-txt: #ceced8;
$red-txt: #F95A61;

//text status-color
$complete-txt: #459830;
$in-progress-txt: #376fff;
$waiting-txt: #de7e0d;

//status colors
$error: #f12113;
$error-outline: rgba(226, 69, 58, 0.16);
$success: #07a242;
$complete: #dbf4dc;
$warning: #ffdddb;
$in-progress: #d4e5ff;
$waiting: #faf2e6;

//box-shadow colors
$box-shadow: 0px 0px 1px rgba(101, 12, 0, 0.12);
$box-shadow-input-default: 0 0 0 3px rgba(91, 77, 255, 0.16);
$box-shadow-input-error: 0 0 0 3px rgba(226, 69, 58, 0.16);

//Other colors
$dividers: rgba(163, 163, 191, 0.24);
$grey: #b1c2cf;
$tertiary: #b6b6d0;
$white: #ffffff;
$primary: #386ff6;
$blue: #008eff;
$grey-4: #e9e6f0;
$typography: #000000;
$color-breadcrumbs-link: #77779a;
$color-breadcrumbs-disabled: #b6b6d0;
$grey-dark-1: #819197;
$grey-dark-2: #3c484e;
$grey-dark-3: #1f2d3b;
$grey-light-1: #d9e3ea;
$grey-light-2: #ecf2f6;
$grey-light-3: #f5f8fa;
$admin-grey: #222b33;
$admin-grey80: rgba(34,43,51,0.8);

$color-simple-black: #202025;
$color-simple-white: #ffffff;
$color-simple-primary: #386ff6;
$color-simple-secondary: #77779a;
$color-simple-light-gray: #f2f3f8;

//Border radius
//---------------------------------

$border-radius-button: 6px;
$border-radius-tooltip: 4px;
$border-radius-cell: 4px;
$border-radius-checkbox: 3px;

// Typography
//---------------------------------
$font-family--default: 'Roboto', sans-serif;
$font-family--questrial: 'Questrial', sans-serif;

// Font size
//---------------------------------

$font-size-44: 44px;
$font-size-32: 32px;
$font-size-24: 24px;
$font-size-20: 20px;
$font-size-16: 16px;
$font-size-14: 14px;
$font-size-12: 12px;
$font-size-11: 11px;
$font-size-12: 12px;

// Line height
//---------------------------------

$line-height-62: 62px;
$line-height-40: 40px;
$line-height-32: 32px;
$line-height-24: 24px;
$line-height-20: 20px;
$line-height-16: 16px;

// Font weight
//---------------------------------

$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;


// animation
//----------------------------------

$animation-timing-function: cubic-bezier(.25,.46,.45,.94);