@import 'src/styles/variables.scss';

.sider {
  grid-area: nav;
  box-sizing: border-box;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;

  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  &.dark {
    background-color: $admin-grey;
  }

  &.light {
    background-color: $grey-light-3;
  }
}

.sider.long {
  width: 96px;
  position: sticky;
  top: 24px;
  transition: width .3s,left .3s $animation-timing-function;

  @media (max-width: 991px) {
    display: none;
  }

  &.opened {
    width: 240px;
  }
}

.header {
  min-height: 144px;

  &.dark {
    background-color: $black50;
    color: $grey-dark-2;
  }

  &.light {
    background-color: $grey-light-3;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
