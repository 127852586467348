
.timeline {
	width: 180px;
}

.scheduleWrap {
	height: 350px;
	overflow-y: auto;
	width: 100%;
	padding-top: 24px;
}

:global {
	.ant-timeline-item-content {
		width: 350px !important;
	}
}

