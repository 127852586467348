@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 24px;
  border-bottom: 1px solid $grey-light-3;
}

.profile {
  @include reset-button;
  display: flex;
  align-items: center;
  gap: 8px;
}

.button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.hideMobile {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.mobileBlock {
  @media screen and (min-width: 768px) {
    display: none;
  }
}