@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.dropdownButton {
	@include reset-button;
	cursor: pointer;
}

.form {
	display: flex;
	flex-direction: column;
	gap: 14px;
	width: 100%;
	margin-top: 14px;
}