@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.sider {
	height: 100%;
}

.logout {
	@include reset-button
}

.content {
	background-color: $white;
	width: 100% !important;
	height: calc(100vh - 62px);
	overflow-y: auto;
}

.contentWrapper {
	width: 100% !important;
	gap: 0 !important;
	display: flex;
	align-items: flex-start;
	height: 100%;
}