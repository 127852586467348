@import 'src/styles/variables.scss';

:global {
  .ant-switch {
    height: 20px;
    line-height: 20;
    min-width: 36px;
  }

  .ant-switch-handle {
    height: 16px;
    width: 16px;
  }

  .ant-switch-checked {
    background-color: $primary-bg;
  }
}
