@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 24px;
	grid-column-gap: 14px;
	grid-row-gap: 24px;
	margin-bottom: 24px;
	max-width: 680px;
}

.content {
	display: grid;
	grid-template-columns: 680px 1fr;
	grid-column-gap: 14px;
	align-items: start;
	margin-top: 24px;
}

.timelineContent {
	border-left: 1px solid $grey-light-1;
	padding-left: 24px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	height: 500px;
	overflow-y: auto;
}

.timeline {
	width: 180px;
}

:global {
	.ant-timeline-item-content {
		width: 350px !important;
	}
}