@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.breadcrumbs {
  margin-bottom: 16px;
}

.header {
  display: flex;
  gap: 23px;
  border-bottom: 1px solid $grey-light-1;
  padding-bottom: 24px;
  margin-top: 14px;
}

.content {
  display: flex;
  gap: 24px;
  margin: 14px 0;
}

.comment {
  max-width: 575px;
  margin-bottom: 24px;
}